import React, { type FC } from "react";
import { type Article, type ArticleExternal, type ArticleTeaserProps } from "../../generated-types";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { sc } from "../../_Base/Jss";
import { type ItemReference } from "~/foundation/Jss";
import { ChakraRouterLink } from "../../_Base/Components/Link/ChakraRouterLink";
import { AspectRatio, Box, Flex, Text as ChakraText, Link } from "@chakra-ui/react";
import { imageSizes } from "../../_Base/Theme";
import { type Tag } from "~/foundation/SitecoreContent/generated-types";
import cursor from "~/foundation/Assets/svg/cursors/hover-cursor.svg";
import cursorExt from "~/foundation/Assets/svg/cursors/hover-cursor-ext.svg";
import { type GlobalDictionary } from "../../dictionary";
import { useTranslation } from "~/foundation/Dictionary";

const ArticleTeaser: FC<ArticleTeaserProps> = ({ fields }) => {
	const [t] = useTranslation<GlobalDictionary>();
	const { sitecoreContext } = useSitecoreContext();

	const article = fields?.articleReference as ItemReference<Article & ArticleExternal> | undefined;

	const LinkComponent = article?.fields?.externalLink ? Link : ChakraRouterLink;
	const linkProps = article?.fields?.externalLink ? { href: article.fields.externalLink?.value?.href, target: article.fields.externalLink?.value?.target } : { to: article?.url };

	if (!article && sitecoreContext.pageEditing) {
		return (
			<ChakraText>
				[No article reference found]
			</ChakraText>
		)
	}

	return (
		<>
			{article && (
				<LinkComponent
					position="relative"
					variant="unstyled"
					display="block"
					cursor={article ? `url(${article.fields?.externalLink ? cursorExt : cursor}) 20 20, pointer` : undefined}
					{...linkProps}
				>
					<Flex gap={["4", null, null, "6"]} py="4" borderY="1px solid var(--chakra-colors-primary-greyMedium)">
						{(article.fields.image?.value?.src || sitecoreContext.pageEditing) &&
							<Box>
								<AspectRatio ratio={16 / 9} w={["6.25rem", null, null, "11rem"]} overflow="hidden" borderTopStartRadius="var(--chakra-sizes-articleCardRoundedCorner)" borderBottomEndRadius="var(--chakra-sizes-articleCardRoundedCorner)">
									<sc.AdvanceImage
										fields={article.fields}
										itemId={article.id}
										fieldName="image"
										editable={false}
										srcSet={[imageSizes.defaultWide.sm]}
										sizes="384px"
										sx={{
											objectFit: "cover",
											objectPosition: "center center",
											minWidth: "100%",
											minHeight: "100%",
										}}
									/>
								</AspectRatio>
							</Box>
						}

						<Box mt={["-2", null, null, "0"]}>
							{(!article.fields.externalLink && article.fields.topicTags && article.fields.topicTags?.length > 0) &&
								<ChakraText variant="tagLight" display="inline-block" mb={["1", null, null, "3"]} textTransform="lowercase">
									{article.fields.topicTags?.map((tag: ItemReference<Tag>, index: number) => (
										<React.Fragment key={"tag" + index}>
											<sc.Text field={tag.fields.title} editable={false} />
											{(article.fields.topicTags?.length && index < article.fields.topicTags?.length - 1) &&
											<span>, </span>
											}
										</React.Fragment>
									))}
								</ChakraText>
							}

							{article.fields.externalLink?.value?.href && (
								<ChakraText
									variant="tagLight"
									display="inline-block"
									textAlign="end"
									border="1px solid"
									borderColor="primary.green"
									px="0.38rem"
									py={2}
									borderRadius="0.25rem"
									lineHeight={1}
									mb={["1", null, null, "3"]}
									textTransform="lowercase"
								>
									{t("article.externalLabel")}
								</ChakraText>
							)}

							{(article.fields?.listTeaserTitle?.value || sitecoreContext.pageEditing) &&
								<ChakraText size="headline4">
									<sc.Text field={article.fields?.listTeaserTitle}/>
								</ChakraText>
							}
						</Box>
					</Flex>
				</LinkComponent>
			)}
		</>
	);
};

export default ArticleTeaser;